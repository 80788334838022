import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LetterAnimation from '../LetterAnimation/LetterAnimation';
import './AboutComponent.scss';
import { useEffect, useState } from 'react';
import { faAngular, faCss3, faHtml5, faJsSquare, faReact, faVuejs } from '@fortawesome/free-brands-svg-icons';
import CustomLoaderComponent from '../CustomLoader/CustomLoaderComponent';

const AboutComponent = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    
    useEffect(() => {
        const timeoutId = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 4000);
    
        return () => clearTimeout(timeoutId); 
    }, []);

    return (
        <>
            <div className='container about-page'>            
                <div className='text-template'> 
                    <h2>
                        <LetterAnimation 
                            letterClass={letterClass} 
                            stringArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
                            idx={15}
                        /> 
                    </h2>
                    <p>
                    Hello, I'm Mateo, a dedicated front-end developer passionate about creating captivating and functional websites.
                    </p>

                    <p>
                    Proficient in Angular, React.js, and Vue.js, I specialize in translating design concepts into responsive and interactive interfaces.
                    My expertise lies in HTML, CSS, and JavaScript, ensuring optimal performance across devices and browsers. 

                    </p>

                    <p>
                    I excel in collaborating with designers and back-end developers, delivering solutions that exceed expectations through well-designed user interfaces.                
                    </p>
                    
                </div>
            <div className='cube-container'>
                <div className='cube-spinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faAngular} color ="#DD0031" />
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faHtml5} color ="#F06529" />
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faReact} color ="#5ED4F4" />
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faCss3} color ="#28A4D9" />
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faVuejs} color ="#42b883" />
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faJsSquare} color ="#EFD81D" />
                    </div>
                </div>
            </div>
            </div>
            <CustomLoaderComponent/>
        </>
        
    )
}

export default AboutComponent;