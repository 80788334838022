import React from 'react';
import './LogoAnimation.scss';


const LogoAnimation = () => {
    return (
        <>
            <div className='container logo-container'>
                <svg data-name="reefe 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 531 526" id="logo">
                    <polyline className="cls-2 poly1" points="166.78 352.93 265.78 231.31 364.78 352.93"/>
                    <line className="cls-2 line1" x1="229.01" y1="257.2" x2="160.11" y2="172.56"/>
                    <polyline className="cls-2 poly2" points="288.92 275.7 265.78 304.51 242.64 275.7"/>
                    <line className="cls-2 line1" x1="373.85" y1="172.56" x2="304.95" y2="257.2"/>
                    <circle className="cls-2 circle1" cx="172.44" cy="262.42" r="11.31"/>
                    <circle className="cls-2 circle2" cx="359.12" cy="262.42" r="11.31"/>
                    <circle className="cls-2 circle3" cx="265.78" cy="338.79" r="12"/>
                    <circle className="cls-2 circle4" cx="265.78" cy="191.71" r="12"/>
                    <path className="cls-1 path" d="M265.6,15c33.55,0,66.09,6.57,96.73,19.53,29.59,12.52,56.17,30.43,78.99,53.26,22.82,22.82,40.74,49.4,53.26,78.99,12.96,30.64,19.53,63.18,19.53,96.73s-6.57,66.09-19.53,96.73c-12.52,29.59-30.43,56.17-53.26,78.99-22.82,22.82-49.4,40.74-78.99,53.26-30.64,12.96-63.18,19.53-96.73,19.53s-66.09-6.57-96.73-19.53c-29.59-12.52-56.17-30.43-78.99-53.26-22.82-22.82-40.74-49.4-53.26-78.99-12.96-30.64-19.53-63.18-19.53-96.73s6.57-66.09,19.53-96.73c12.52-29.59,30.43-56.17,53.26-78.99,22.82-22.82,49.4-40.74,78.99-53.26,30.64-12.96,63.18-19.53,96.73-19.53M265.6,13C127.25,13,15.1,125.15,15.1,263.5s112.15,250.5,250.5,250.5,250.5-112.15,250.5-250.5S403.94,13,265.6,13h0Z"/>
                    <rect className="cls-2 rect" x="60.5" y="58.63" width="410.66" height="410.66"/>
                </svg>
            </div>
        </>
        
    );

};

export default LogoAnimation;