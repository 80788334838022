import { Link, NavLink } from 'react-router-dom';
import './UpperNavBarComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faUser, faBars, faClose, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import  NavLogo from "../../assets/images/page-logo-small.svg";

const UpperNavBarComponent = ({ showNav, setShowNav }) => {
    
    return (
        <div className='nav-bar upper-navBar'>
            <div className='nav-container'>
            <Link to='/' className='logo'>
            <div className='logo-placeholder' onClick={() => setShowNav(false)}>
                <img src={NavLogo} className='logo-image' alt='Mateo Neglia Logo' />
                <h1 id='main-title'>Mateo Neglia</h1>
            </div>
        </Link>
        <nav className={showNav ? 'mobile-show' : ''}>
            <NavLink to="/" activeclassname="active" onClick={() => setShowNav(false)}>
                    <FontAwesomeIcon 
                        icon={faHome}
                        color="#ED6663"/>
            </NavLink>
            <NavLink to="/about" activeclassname="active" className='about-link' onClick={() => setShowNav(false)} >
                <FontAwesomeIcon 
                    icon={faUser} 
                    color="#ED6663"/>
            </NavLink>
            <NavLink to="/portfolio" activeclassname="active" className='portfolio-link' onClick={() => setShowNav(false)} >
                <FontAwesomeIcon 
                    icon={faSuitcase} 
                    color="#ED6663"/>
            </NavLink>
            <NavLink to="/contact" activeclassname="active" className='contact-link' onClick={() => setShowNav(false)} >
                <FontAwesomeIcon 
                    icon={faEnvelope} 
                    color="#ED6663"/>
            </NavLink>
            <FontAwesomeIcon 
                onClick={() => setShowNav(false)}
                icon={faClose}
                color="#FFA372"
                size="3x"
                className='close-icon' />
        </nav>       

            <FontAwesomeIcon 
            onClick={() => setShowNav(true)}
            icon={faBars}
            color="#FFA372"
            size="3x"
            className='hamburger-icon' />
            </div>
        </div>
    )
    
};

export default UpperNavBarComponent;