import React, { useEffect, useRef, useState } from 'react';
import './CustomLoaderComponent.scss';
import PageLogo from '../../assets/images/page-logo.svg';

const CustomLoaderComponent = () => {
    const [progress, setProgress] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const preloader = useRef();

    const preloaderCheckRequest = () => {
        let a = 0;
        const loader = setInterval(() => {
            a += 3;
            setProgress(a);
            if (a >= 99) {
                clearInterval(loader);
                setShowLoader(false); // Hide the loader when loading is complete
            }
        }, 25);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            preloaderCheckRequest();
        }, 1000); // Start loading after 2 seconds (adjust as needed)
        return () => clearTimeout(timeout);        
    }, []);

    useEffect(() => {
      if (!showLoader) {
          const hideTimeout = setTimeout(() => {
              preloader.current.style.display = 'none';
          }, 1500); // Adjust this timeout based on your animation duration
          return () => clearTimeout(hideTimeout);
      }
    }, [showLoader]);

    return (
        <div className={`preloader ${showLoader ? 'show' : ''}`} ref={preloader}>
            <div className="inner">
                <img src={PageLogo} alt="JavaScript Developer Loader, Web Developer Loader, Front End Developer Loader" />
                <span>Mateo is working</span>
                <div className="progress-bar">
                    <div className="progress-bar_bg">
                        <div style={{ width: `${progress}%` }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomLoaderComponent;
