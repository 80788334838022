import React from 'react';
import './MarqueeComponent.scss';

const MarqueeComponent = ({ brandArray }) => {
  return (
    <>
      <div className='marquee-component'>
        <ul className="marquee-content scroll">
          {brandArray.map((brand, index) => (
            <li key={index}>{brand}</li>
          ))}
        </ul>

        <ul className="marquee-content scroll" aria-hidden="true">
          {brandArray.map((brand, index) => (
            <li key={index}>{brand}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MarqueeComponent;
