import { Link } from "react-router-dom";
import './HomeComponent.scss';
import { useState, useEffect } from "react";
import LetterAnimation from "../LetterAnimation/LetterAnimation";
import CustomLoaderComponent from '../CustomLoader/CustomLoaderComponent';
import LogoAnimation from "./LogoAnimation/LogoAnimation";

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const jobArray = [' ', 'W', 'e', 'b', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r'];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);

    return () => clearTimeout(timeoutId); 
  }, []);

  return (
     <>
      <div className="container home-page">
        <div className="home-text">
          <h2>
            Hi! I'm Mateo,          
            <br />
            <span className="your-span">your </span>
            
            <LetterAnimation 
              letterClass={letterClass}
              stringArray={jobArray}
              idx={15} />
          </h2>
          <h3>Front End Developer / Web Designer</h3>
          <Link 
            to="/contact" 
            className="flat-button">
            Contact Me!</Link>
        </div>
        <LogoAnimation/>
      </div>
      <CustomLoaderComponent/>
     </>
    
  );
};

export default Home;
