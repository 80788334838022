import { Outlet } from 'react-router-dom';
import './LayoutComponent.scss';
import UpperNavBarComponent from '../UpperNavBar/UpperNavBarComponent';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SnackbarProvider } from 'notistack';
import { useState } from "react";

const LayoutComponent = () => {
    const [showNav, setShowNav] = useState(false);
    
    return <div className='App'>
                <UpperNavBarComponent showNav={showNav} setShowNav={setShowNav}/>                
                <div className='main-page'>
                    <span className='tags top-tags'>&lt;body&gt;</span>
                        <SnackbarProvider maxSnack={3}>
                            <Outlet/>
                        </SnackbarProvider>                        
                    <span className='tags bottom-tags'>&lt;/body&gt;
                        <br/>
                        <span className='bottom-tag-html'>&lt;/html&gt;</span>
                    </span>
                    <ul className={showNav ? 'work-links mobile-nav' : 'work-links'}>
                        <li>
                            <a target='_blank' 
                            rel='noreferrer' 
                            href='https://www.linkedin.com/in/mateoneglia/'>
                                <FontAwesomeIcon                                    
                                    icon={faLinkedin} 
                                    color="#ED6663" 
                                    className="anchor-icon"/>
                            </a>
                        </li>
                        <li>
                            <a target='_blank' 
                            rel='noreferrer' 
                            href='https://github.com/MateoNeglia'>
                                <FontAwesomeIcon                                    
                                    icon={faGithub} 
                                    color="#ED6663" 
                                    className="anchor-icon"/>
                            </a>
                        </li>
                    </ul>
                </div>
           </div>
}

export default LayoutComponent;