import React, { useEffect } from 'react';
import './PortfolioComponent.scss';
import CustomLoaderComponent from '../CustomLoader/CustomLoaderComponent';
import MarqueeComponent from './MarqueeComponent/MarqueeComponent';
import ProjectCardComponent from './ProjectCardComponent/ProjectCardComponent';
import { useSnackbar } from 'notistack';

const PortfolioComponent = () => {
    const { enqueueSnackbar } = useSnackbar(); 

    useEffect(() => {
        const messages = ["The projects displayed on the cards are mostly college-related and playground apps"];       
        
        const timer = setTimeout(() => {            
            messages.forEach(message => {                
                enqueueSnackbar(message);                
            });            
        }, 2000); 

        return () => clearTimeout(timer);
    }, [enqueueSnackbar]);

    let brands = ['equifax.com', 'bluehost.com', 'networksolutions.com', 'web.com', 'hostgator.com', 'register.com', 'domain.com', 'digidex.net'];
    let projects = [
        {
        title: "Listener's Choice",
        description: 'Vue | MySql | Spotify API',
        image: 'images/projects-data/vue-listeners-choice/mobile-apps-tablet.png',
        gallery: 'images/projects-data/vue-listeners-choice/vue-listeners-mockup.jpg',
        isDeployed: false,
        page: 'https://mvp-rick-api-mateoneglia.vercel.app/',
        repo: 'https://github.com/MateoNeglia/vue-app-with-spotify-api'
        },
        {
            title: "Collector's Corner",
            description: 'PHP | MySql',
            image: 'images/projects-data/php-collectors-corner/collectors-php-final-tablet.png',
            gallery: 'images/projects-data/php-collectors-corner/collectors-php-mockup.jpg',
            isDeployed: false,
            page: 'https://mvp-rick-api-mateoneglia.vercel.app/',
            repo: 'https://github.com/MateoNeglia/collectors-corner'
        },
        {
            title: "Rick's Crazy Lab",
            description: 'Javascript | GraphQL | Rick and Morty API',
            image: 'images/projects-data/graphql-javascrip-rick/graphql-javascrip-tablet.png',
            gallery: 'images/projects-data/graphql-javascrip-rick/collectors-php-mockup.jpg',
            isDeployed: true,
            page: 'https://mvp-rick-api-mateoneglia.vercel.app/',
            repo: 'https://github.com/MateoNeglia/mvp-rick-api'
        },
        {
            title: "React Playground Gatherer",
            description: 'React | Several APIs',
            image: 'images/projects-data/react-test.png',
            gallery: 'images/projects-data/react-test.png',
            isDeployed: true,
            page: 'https://react-playground-gatherer.vercel.app/',
            repo: 'https://github.com/MateoNeglia/React-Playground-Gatherer'
        },
    ]


    return (
        <>
            <div className='container portfolio-page'>             
                <div className='card-component-container'>
                    <ProjectCardComponent projectArray={projects}/>
                </div>
                <div className="main-marquee">
                    <MarqueeComponent brandArray={brands}/>
                </div>
            </div>            
            <CustomLoaderComponent/>
        </>
        
    );

};

export default PortfolioComponent;