import './App.scss';
import { Routes, Route } from 'react-router-dom';
import LayoutComponent from './components/Layout/LayoutComponent';
import Home from './components/Home/HomeComponent';
import AboutComponent from './components/About/AboutComponent';
import ContactComponent from './components/Contact/ContactComponent';
import './assets/styles/global-styles.scss';
import PortfolioComponent from './components/Portfolio/PortfolioComponent';
import ThankYouComponent from './components/ThankYou/ThankYou';
import NotFoundComponent from './components/NotFound/NotFound';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutComponent />}>
          <Route index element={<Home />}/>
          <Route path='about' element={<AboutComponent />}/>
          <Route path='portfolio' element={<PortfolioComponent />}/>
          <Route path='contact' element={<ContactComponent />}/>
          <Route path='thank-you' element={<ThankYouComponent />}/>
          <Route path='*' element={<NotFoundComponent />}/>
        </Route>        
      </Routes>
    </>
  );
}

export default App;
