import React, { useState } from 'react';
import './ProjectCardComponent.scss';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/system';

const ProjectCardComponent = ({ projectArray }) => {
  const [openDialogs, setOpenDialogs] = useState(Array(projectArray.length).fill(false));

  const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
      backgroundColor: '#c3c2c0', 
    },
    '& .MuiTypography-root': {
      color: '#43658B',
      fontWeight: 600,
    }
  });

  const closeButtonStyles = {
    background: 'transparent',
    textTransform: 'uppercase',
    border: '2px solid #ED6663',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#ED6663',
    transition: 'background-color 0.3s',
    '&:hover': {
    backgroundColor: '#ED6663',
    color: 'white',
  }
  };

  const buttonStyles = {
    background: 'transparent',
    textTransform: 'uppercase',
    border: '2px solid #43658B',
    borderRadius: '4px',
    fontSize: '14px',
    color: '#43658B',
    transition: 'background-color 0.3s',
    '&:hover': {
    backgroundColor: '#43658B',
    color: 'white',
  }
  };

  const handleClickOpen = (index, isDeployed, page) => {
    if(!isDeployed) {
      const newOpenDialogs = [...openDialogs];
      newOpenDialogs[index] = true;
      setOpenDialogs(newOpenDialogs);
    } else {
      window.open(page, '_blank');
    }    
  };

  const handleClose = (index) => {
    const newOpenDialogs = [...openDialogs];
    newOpenDialogs[index] = false;
    setOpenDialogs(newOpenDialogs);
  };

  return (
    <>
      <div className='project-card-container'>
        {projectArray.map((project, index) => (
          <div className='project-card image-box' key={index}>
            <img className='portfolio-image' src={project.image} alt={project.title} />
            <div className='content'>
              <h3 className='title'>{project.title}</h3>
              <p className='description'>{project.description}</p>              
              <Button className="btn" onClick={() => handleClickOpen(index, project.isDeployed, project.page)}>View</Button>
              <StyledDialog open={openDialogs[index]} onClose={() => handleClose(index)}>                
                <DialogContent>
                  <img className='portfolio-image' src={project.gallery} style={{ maxWidth: '100%', height: 'auto' }} alt={project.title}/>
                </DialogContent>
                <DialogActions>
                  <Button href={project.repo} target='_blank' style={buttonStyles}>Go to Repo</Button>
                  <Button style={closeButtonStyles} onClick={() => handleClose(index)}>Close</Button>
                </DialogActions>
              </StyledDialog>
            </div>            
          </div>            
        ))}
      </div>
    </>
  );
};

export default ProjectCardComponent;
