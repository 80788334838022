import React, { useState, useEffect, useRef } from 'react';
import './ContactComponent.scss';
import LetterAnimation from '../LetterAnimation/LetterAnimation';
import emailjs from '@emailjs/browser';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import CustomLoaderComponent from '../CustomLoader/CustomLoaderComponent';
import { useNavigate } from 'react-router-dom';

const ContactComponent = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const refForm = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_9lgmahi',
        'template_9omnxva',
        refForm.current,
        'uFJtxji77V_RX5ENb'
        )
      .then(
        () => {
        navigate('/thank-you');
        },
      () => {
        alert('The Message failed to be sent');
      }
    );
  };

  return (
    <>
      <div className="container contact-page">
        <div className='text-template'> 
          <h2>
            <LetterAnimation 
              letterClass={letterClass} 
              stringArray = {['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e']}
              idx={15}
            /> 
          </h2>
          <p>
            Thank you for reaching out! Whether you have questions, feedback, or job opportunities, I'm eager to connect. 
            Feel free to use the form below. I look forward to hearing from you and discussing all things front-end development!
          </p>
          <div className='contact-form'>
            <form ref={refForm} onSubmit={handleEmail}>
              <ul>
                <li className='half'>
                  <input type='text' name='name' placeholder='Name' value={formData.name} onChange={handleInputChange} />
                </li>
                <li className='half'>
                  <input type='email' name='email' placeholder='youremail@mail.com' value={formData.email} onChange={handleInputChange} />
                </li>
                <li>
                  <input type='text' name='subject' placeholder='Subject' value={formData.subject} onChange={handleInputChange} />
                </li>
                <li>
                  <textarea placeholder='Message' name='message' value={formData.message} onChange={handleInputChange}></textarea>
                </li>
                <li>
                  <input type='submit' className='flat-button send-button' value='Send'/>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className='info-map'>
            Mateo Neglia,
            <br/>
            Argentina,
            <br/>
            Buenos Aires, Devoto
            <br/>
            <span>mateo.neglia@gmail.com</span>
            
        </div>
        <div className='map-wrap'>
            <MapContainer center={[-34.5818626, -58.5020496]} zoom={15} scrollWheelZoom={false}>
                <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[-34.5818626, -58.5020496]}>
                <Popup>Mateo lives here, let's chat!</Popup>
                </Marker>
            </MapContainer>
        </div>
      </div>
      <CustomLoaderComponent/>
    </>
  );
};

export default ContactComponent;
