import React from 'react';
import { useState, useEffect } from "react";
import './ThankYou.scss';
import CustomLoaderComponent from '../CustomLoader/CustomLoaderComponent';
import LetterAnimation from "../LetterAnimation/LetterAnimation";

const ThankYouComponent = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const jobArray = ['T', 'h', 'a', 'n', 'k', ' ', 'Y', 'o', 'u', '!'];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);

    return () => clearTimeout(timeoutId); 
  }, []);

  return (
    <>
      <div className="thank-you-page">
        <h1>
          <LetterAnimation 
                letterClass={letterClass}
                stringArray={jobArray}
                idx={15} />
        </h1>
        <div className='thank-you-text'>
          <span id="sub-text">for contacting me!</span>
          <p>I'ill answer your message in a few!</p>
          <p>Wanna go back to the main page?
            <a href='/'> Click here!</a>
          </p>
        </div>
      </div>
      <CustomLoaderComponent/>
    </>
  );
};

export default ThankYouComponent;