import React from 'react';
import './NotFound.scss';
import CustomLoaderComponent from '../CustomLoader/CustomLoaderComponent';

const NotFoundComponent = () => {
  return (
    <>
      <div className="container notfound-page">    
        <h2>404 - Not Found</h2>
        <p>Sorry, the page you are looking for does not exist.
          <a href='/'> TAKE ME BACK!</a></p>    
      </div>
      <CustomLoaderComponent/>
    </>
  );
};

export default NotFoundComponent;